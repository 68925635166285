'use client';

import { UserIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import Button from '@/components/button';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormikInput from '@/components/input/FormikInput';
import registerPlayer from '@/api/auth/registerPlayer';
import { Bounce, toast } from 'react-toastify';
import PlayerAvatar from '@public/icons/player-avatar.svg';
import { loginAction } from '@/app/(auth)/login/actions';
import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/navigation';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  passwordAgain: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
  nickname: yup.string().required('Nickname is required'),
});

interface RegisterFormProps {
  onRegister?: Dispatch<SetStateAction<boolean>>;
}

const RegisterForm = ({ onRegister = () => {} }: RegisterFormProps) => {
  const router = useRouter();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordAgain: '',
        nickname: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          await registerPlayer(values);
          toast.info('Registration successful', {
            position: 'top-right',
            autoClose: Infinity,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            transition: Bounce,
          });
          await loginAction(values.email, values.password);
          router.refresh();
          onRegister(false);
        } catch (error) {
          actions.setErrors({ email: 'Email is already taken' });
        }
      }}
    >
      {(props) => (
        <Form>
          <div className="flex flex-col gap-2">
            <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
              <h2 className="text-2xl font-bold">Create an GOCORE account</h2>
              <p className="hidden md:block text-base font-normal leading-6">
                Showoff your knowledge in the GOCORE community. Climb through
                the leader boards and become the best tipster.
              </p>
            </div>
            <div className="self-center flex flex-col gap-5 w-full max-w-[480px]">
              <div className="flex flex-col gap-2 w-full">
                <span className="text-base font-normal text-blue-gray">
                  Login info
                </span>

                <FormikInput
                  type="text"
                  name="email"
                  startIcon={<UserIcon />}
                  placeholder="Your email "
                />

                <FormikInput
                  type="password"
                  name="password"
                  startIcon={<LockClosedIcon />}
                  placeholder="Your password"
                />

                <FormikInput
                  type="password"
                  name="passwordAgain"
                  startIcon={<LockClosedIcon />}
                  placeholder="Confirm Password"
                />
              </div>

              <div>
                <span className="text-base font-normal text-blue-gray">
                  Set Nickname (required)
                </span>
                <FormikInput
                  type="text"
                  name="nickname"
                  startIcon={<PlayerAvatar className="w-[26px]" />}
                  placeholder="GOCore Nickname"
                />
                <span className="text-sm">
                  Used to display on tips, pickems and leaderboards
                </span>
              </div>

              <Button
                type="submit"
                variant="primary"
                disabled={props.isSubmitting}
              >
                Register
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
