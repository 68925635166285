'use client';
import { createContext, useContext, useState, ReactNode } from 'react';

export type GameFilter = 'ALL' | 'LOL' | 'CS' | 'DOTA';

export interface IGameFilter {
  // TODO: manage switching games from header and apply theme to rss and etc
}

// Define a type for the context value
interface MyContextType {
  value: GameFilter;
  setValue: (newValue: GameFilter) => void;
}

// Create the context with a default value
const MyContext = createContext<MyContextType | undefined>(undefined);

// Export a custom hook to use the context
export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};

interface MyProviderProps {
  children: ReactNode;
}

export const MyProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [value, setValue] = useState<GameFilter>('ALL');

  return (
    <MyContext.Provider value={{ value, setValue }}>
      {children}
    </MyContext.Provider>
  );
};
