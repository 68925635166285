'use client';

import React, { Fragment, useContext, useState } from 'react';
import { DialogPanel, TransitionChild } from '@headlessui/react';
import { Dialog, Transition, DialogBackdrop } from '@headlessui/react';
import { GoCoreLogo } from '../logo';
import { XMarkIcon } from '@heroicons/react/20/solid';
import RegisterForm from '../forms/register';

const RegistrationModalContext = React.createContext<{
  isOpen: boolean;
  toggleIsOpen: () => void;
}>({
  isOpen: false,
  toggleIsOpen: () => {},
});

export const Provider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <RegistrationModalContext.Provider value={{ isOpen, toggleIsOpen }}>
        {children}
      </RegistrationModalContext.Provider>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={toggleIsOpen}>
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-light-gray px-4 py-5 md:p-12 text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between mb-5">
                    <GoCoreLogo />
                    <div className="flex flex-row gap-2">
                      <button type="button" onClick={() => toggleIsOpen()}>
                        <XMarkIcon className="h-9 w-9 text-white" />
                      </button>
                    </div>
                  </div>
                  <RegisterForm onRegister={toggleIsOpen} />
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

// Export a custom hook to use the context
export const useRegisterDialog = () => {
  const context = useContext(RegistrationModalContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};
